import React, { useEffect, useState } from "react";
import useStocks from "../../../hooks/useStocks";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";
import InputDate from "../../global/InputDate";

interface StockItem {
  name: string;
  price: number;
}

interface StockReportItem {
  item: StockItem;
  type: string;
  quantity: number;
  balance: number;
  updated_at: string;
}

interface AvailableItem {
  name: string;
  quantity: number;
  bought_on: string;
  total_price: number;
}

interface StockReport {
  available: AvailableItem[];
  almost: AvailableItem[];
  out: AvailableItem[];
}

const ListStockReports: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const { loading, getStockReport, stockReport } = useStocks();

  useEffect(() => {
    getStockReport();
  }, []);

  const formatDateTime = (dateTimeString: string): string => {
    const dateObject = new Date(dateTimeString);
    return dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    });
  };

  const handleDateSelector = (e: any) => {
    const { name, value } = e;
    if (name === "start_date") {
      setStartDate(value);
    }
    if (name === "end_date") {
      setEndDate(value);
    }
  };

  const handleFilter = () => {
    getStockReport(startDate, endDate);
  };

  return (
    <div>
      <div className="form-row">
        <div className="col-sm-2">
          <InputDate
            name="start_date"
            handleChange={handleDateSelector}
            placeholder="Please provide the start date"
          />
        </div>
        <div className="col-sm-2">
          <InputDate
            name="end_date"
            handleChange={handleDateSelector}
            placeholder="Please provide the end date"
          />
        </div>
        <div className="col-sm-4">
          <button className="btn btn-info" onClick={handleFilter}>
            Filter
          </button>
        </div>
      </div>
      <hr />
      {loading ? (
        <EllipsisLoader />
      ) : (
        <div>
          {stockReport?.length > 0 ? (
            <>
              <h5>Available</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Item Type</th>
                    <th>Quantity</th>
                    <th>Balance</th>
                    <th>Amount</th>
                    <th>Last refill</th>
                  </tr>
                </thead>
                <tbody>
                  {stockReport.map((stock: StockReportItem, index: number) => (
                    <tr key={index}>
                      <td>{stock?.item?.name}</td>
                      <td>{stock.type}</td>
                      <td>{stock.quantity}</td>
                      <td>{stock.balance}</td>
                      <td>{formatCurrency(stock?.item?.price)}</td>
                      <td>{formatDateTime(stock.updated_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ):(<p>No data found ....</p>)}
          {stockReport?.available?.length > 0 && (
            <>
              <h5>Available</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Last refill</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {stockReport.available?.map((item: AvailableItem, index: number) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.bought_on}</td>
                      <td>{formatCurrency(item.total_price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {stockReport?.almost?.length > 0 && (
            <>
              <h5>Almost Out</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Last refill</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {stockReport.almost?.map((item: AvailableItem, index: number) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.bought_on}</td>
                      <td>{formatCurrency(item.total_price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {stockReport?.out?.length > 0 && (
            <>
              <h5>Out of order</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Last refill</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {stockReport.out?.map((item: AvailableItem, index: number) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.bought_on}</td>
                      <td>{formatCurrency(item.total_price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ListStockReports;
