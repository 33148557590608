import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  prchaseOrderDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useCommittees from "../../../hooks/useCommittees";
import useRequisition from "../../../hooks/useRequisition";
import {
  formatbidSelectData,
  formatProductSelectData,
  formatRequisitionSelectData,
  formatStaffSelectData,
  formatSupplierSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import Textarea from "../../global/Textarea";
import Input from "../../global/Input";
import useSuppliers from "../../../hooks/useSuppliers";

interface IAddPurchaseOrderProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddPurchaseOrder = ({ closeModal, refresh }: IAddPurchaseOrderProps) => {
  const [formLoading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(prchaseOrderDefaultData);
  const [items, setItems] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // Updated to string array
  const [selectedPr, setSelectedPr] = useState<any>([]);
  const { loading, getBidAnalysis, bidAnalysis, requisitions } =
    useRequisition("Approved");
  const { approvingCommitte, getApprovingCommitteeMembers } = useCommittees();
  const { suppliers } = useSuppliers();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getApprovingCommitteeMembers(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);
  
  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_requisition_id") {
        setItems(requisitions.find((item: any) => item?.id === value)?.items || []);
        setSelectedPr(requisitions.find((item: any) => item?.id === value));
      }
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    if (value !== "") {
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(purchaseOrder, setPurchaseOrder)) {
      setLoading(true);
      const dataValid = getFormData(purchaseOrder);
      const selectedItemsData = items.filter((item:any) => selectedItems.includes(item.id)); // Filter items based on selectedItems array
      const data = {
        purchase_requisition_id: dataValid.purchase_requisition_id,
        supplier_id: dataValid.supplier_id,
        authorised_by: dataValid.authorised_by,
        delivery_date: dataValid.delivery_date,
        items: selectedItemsData,
        status: "Pending",
        comment: dataValid.comment,
        reference_no: selectedPr?.purchase_requisition_no,
      };

      doCreate("purchase_orders", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New Purchase Order has been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  
  const handleItemSelect = (itemId: string) => {
    const isSelected = selectedItems.includes(itemId);
    let newSelectedItems;

    if (isSelected) {
      newSelectedItems = selectedItems.filter((id: string) => id !== itemId);
    } else {
      newSelectedItems = [...selectedItems, itemId];
    }

    setSelectedItems(newSelectedItems);
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formatRequisitionSelectData(requisitions)}
            id="purchase_requisition_id"
            label="Requisition"
            name="purchase_requisition_id"
            required
            handleChange={handleSelected}
            errorMessage="Please select the requisitions"
            error={purchaseOrder.purchase_requisition_id.error}
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatSupplierSelectData(suppliers)}
            id="supplier_id"
            name="supplier_id"
            label="Supplier"
            handleChange={handleSelected}
            required
            errorMessage="Please select supplier"
            value={purchaseOrder.supplier_id.data}
          />
        
        </div>
        <div className="col-sm-4">
          <Input
            name="reference_no"
            label="Ref No"
            handleChange={handleChange}
            required
            errorMessage="Please select reference number"
            value={selectedPr?.purchase_requisition_no}
          />
        </div>
      </div>

      {/* Dropdown select for items */}
      {(items.length > 0) && (<div className="form-row">
        <div className="col-sm-12">
          <InputSelect
            data={items.map((item: any) => ({ value: item.id, label: item.item.name }))}
            id="item_id"
            label="Select Item (s)"
            name="item_id"
            required
            handleChange={(e: any) => handleItemSelect(e.value)}
            errorMessage="Please select an item"
          />
        </div>
      </div>)}

      {/* Table displaying selected items */}
      {(items.length > 0)  && (<div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
              <th>Price/Unit</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any, index: number) => (
              <tr key={index}>
                {selectedItems.includes(item.id) && (
                  <>
                    <td>{item?.item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.rate}</td>
                    <td>{Number(item?.quantity * item?.rate).toLocaleString()}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>)}

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            label="Comment"
            handleChange={handleChange}
            name="comment"
            error={purchaseOrder.comment.error}
            errorMessage="Please enter some comment about this action"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatStaffSelectData(approvingCommitte)}
            id="authorised_by"
            label="Authorised by"
            name="authorised_by"
            required
            errorMessage="Please the personal who authorised the action"
            error={purchaseOrder.authorised_by.error}
            handleChange={handleSelected}
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            label="Delivery Date"
            name="delivery_date"
            required
            noLimit={true}
            error={purchaseOrder.delivery_date.error}
            errorMessage="Please select date"
            value={purchaseOrder.delivery_date.data}
            handleChange={handleSelected}
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddPurchaseOrder;
