import React, { useState } from "react";
import { ITabsData, MyTabs } from "../../global/MyTab";
import PendingRequisitions from "./PendingRequisitions";
import ApprovedRequisition from "./ApprovedRequisition";
import DeclinedRequisitions from "./DeclinedRequisitions";
import ModalSwitcher from "../../global/ModalSwitcher";
import AddRequisition from "./AddRequisition";

const RequisitionTabs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  
  const ApplicationsTabsData: ITabsData[] = [
    {
      tabId: 1,
      title: "Pending",
      component: <PendingRequisitions />,
    },
    {
      tabId: 2,
      title: "Approved",
      component: <ApprovedRequisition />,
    },
    {
      tabId: 3,
      title: "Declined",
      component: <DeclinedRequisitions />,
    },
  ];


  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  
      const modalId = "add-new-requisition";
      const modalTitle = "Add New Requisition";
      
  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <AddRequisition
            onCancel={handleModalClick}
          />
        }
      />

      <div className="bg-white border-b-2">
        <div className="d-block d-md-flex justify-content-between p-4">
          <div className="d-block">
            <div className="pl-3 row align-items-end">
              <button
                className="btn btn-primary ml-auto mr-3"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                  setOpenModal(0);
                }}
              >
                <i className="fa fa-plus mr-1"></i>Add Requisition
              </button>
            </div>
          </div>
        </div>
        <MyTabs TabsData={ApplicationsTabsData} />
      </div>
    </>
  );
};

export default RequisitionTabs;
