import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { createPayroll } from '../../../api/hr';
import { addPayrollDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import usePayrollItems from '../../../hooks/usePayrollItems';
import usePayrolls from '../../../hooks/usePayrolls';
import useStaff from '../../../hooks/useStaff';
import { ROUTES } from '../../../routes/routes';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

const CreatePayroll = () => {
  const [payroll, setPayroll] = useState(addPayrollDefaultData);
  const { payrollItems, payrollDeducationItems, handleTotalPayChange, handleTotalDeducationChange, netPay, totalDeducation, totalPay } = usePayrollItems();

  const { refresh, resetPayrollForm } = usePayrolls();


  const { formSelectEmployeeData, loading } = useStaff();
  const router = useHistory();

  const [formLoading, setFormLoading] = useState(false);
  const [isRecurring, setIsRecurring] = useState(true);
  const validator = new Validator();


  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    if (value !== '') {
      // update the state
      setPayroll({ ...payroll, ...{ [name]: { ...payroll[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPayroll({ ...payroll, ...{ [name]: { ...payroll[name], error: true, data: value } } });
    }
  }

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== '') {
      // update the state
      setPayroll({ ...payroll, ...{ [name]: { ...payroll[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPayroll({ ...payroll, ...{ [name]: { ...payroll[name], error: true, data: value } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(payroll, setPayroll)) {
      setFormLoading(true);
      const data = {
        staff_id: payroll.staff_id.data,
        employee_no: payroll.employee_no.data,
        pay_date: payroll.pay_date.data,
        total_pay: totalPay,
        deductions: totalDeducation,
        net_pay: netPay,
        //payment_method: payroll.payment_method.data,
        bank_name: payroll.bank_name.data,
        account_no: payroll.account_no.data,
        description: payroll.description.data,
        paid_amount: Number(netPay),
        comments: payroll.comments.data,
        is_recurring: isRecurring,
        recurring_info: payroll.recurring_info.data,
        account_chart_id: payroll.account_chart_id.data

      }

      createPayroll(data).then((response) => {
        if (response.data.success) {
          toast.success('New payroll information succefully saved', successToastOptions);
          refresh();
          resetPayrollForm();
          setTimeout(() => {
            router.push(ROUTES.PAYROLLMANAGEMENT);
          }, 1000);

        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);

      }).finally(() => {
        setFormLoading(false);
      })
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      {/* staff details */}
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formSelectEmployeeData} label="Employee Name" name='staff_id' id='staff_id' required value={payroll.staff_id.data} error={payroll.staff_id.error} errorMessage="Please select the employee" handleChange={handleSelectChange} />
        </div>
        <div className="col-sm-6">
          <Input name='employee_no' label='Employee Number' handleChange={handleChange} value={payroll.employee_no.data} error={payroll.employee_no.error} errorMessage="Please enter the employee number" />
        </div>
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Payroll Date" name='pay_date' required value={payroll.pay_date.data} error={payroll.pay_date.error} errorMessage="Please select the pay date" />
        </div>
      </div>
      {/* end staff details */}
      {/* details */}
      <div className="form-row">
        {/* left */}
        <div className="col-sm-6">
          <div className="form-row bg-secondary p-2 border-right">
            <div className="col font-bold text-white">Description</div>
            <div className="col font-bold text-white">Amount</div>
          </div>
          {/* form inputs */}
          {payrollItems.map(({ id, name, slug }: any) => (
            <div key={id} className="form-row align-items-center p-2">
              <div className="col-sm-6">{name}</div>
              <div className="col font-bold">
                <input className="form-control" name={slug} onBlur={handleTotalPayChange} />
              </div>
            </div>

          ))}
          {/* end form inputs */}
        </div>
        {/* end left */}
        {/* right */}
        <div className="col-sm-6">
          <div className="form-row  bg-secondary p-2">
            <div className="col font-bold text-white">Description</div>
            <div className="col font-bold text-white">Amount</div>
          </div>
          {/* form inputs deducation */}
          {payrollDeducationItems.map(({ id, name, slug }: any) => (
            <div key={id} className="form-row align-items-center p-2">
              <div className="col-sm-6">{name}</div>
              <div className="col font-bold">
                <input name={slug} className="form-control" onBlur={handleTotalDeducationChange} />
              </div>
            </div>

          ))}
          {/* end form inputs */}
        </div>
        {/* end right */}
      </div>
      {/* totals */}
      <div className="form-row">
        <div className="col border-right">
          <div className="form-row  bg-secondary p-2">
            <div className="col font-bold text-white">Total Pay</div>
            <div className="col font-bold text-white">
              <input value={totalPay} readOnly name='total_pay' onChange={handleChange} />
            </div>
          </div>

        </div>
        <div className="col-sm-6">
          <div className="form-row  bg-secondary p-2">
            <div className="col font-bold text-white">Total Deducations</div>
            <div className="col font-bold text-white">
              <input value={totalDeducation} readOnly name='deducations' onChange={handleChange} />
            </div>
          </div>
          <div className="form-row  bg-secondary p-2">
            <div className="col font-bold text-white">Net Pay</div>
            <div className="col font-bold text-white">
              <input value={netPay} readOnly name='net_pay' onChange={handleChange} />
            </div>
          </div>

        </div>
      </div>
      {/* end totals */}
      {/* end details */}
      {/* bank details */}
      <div className="mt-2">
        <div className="form-row bg-secondary p-2 ">
          <div className="col font-bold text-white">Net Pay Distribution</div>
        </div>

        <div className="form-row p-2">

          <div className="col-sm-6">
            <Input handleChange={handleChange} label="Paid Amount" value={String(netPay)} name='paid_amount' error={payroll.paid_amount.error} errorMessage="Please enter the paid amount" required />
          </div>
          
          <div className="col-sm-6">
            <Input
            handleChange={handleChange}
            name="description"
            label="Description"
            value={payroll.description.data}
            error={payroll.description.error} errorMessage="Please enter the description"
            required
          />
            
          </div>
          <div className="col-sm-12">
            
            <Textarea
            handleChange={handleChange}
            name="comments"
            label="Comments"
            value={payroll.comments.data}
            error={payroll.comments.error} errorMessage="Please enter the comments"
            required
          />
          </div>
        </div>

      </div>
      {/* end bank details */}
      <div className="mt-2 border border-info">
        <div className="form-row bg-info p-2 m-0">
          <div className="col font-bold text-white">Ban Details</div>
        </div>

        <div className='p-2'>
          <div className="form-row p-2">
          <div className="col-sm-6">
            <Input handleChange={handleChange} label="Bank Name" value={payroll.bank_name.data} name='bank_name' error={payroll.bank_name.error} errorMessage="Please enter the bank name" required />
          </div>
          <div className="col-sm-6">
            <Input handleChange={handleChange} label="Account Number" value={payroll.account_no.data} name='account_no' error={payroll.account_no.error} errorMessage="Please enter the account" required />
          </div>
            
            
          </div>
        </div>

      </div>
      {/* recurring payment */}
      <div className="mt-2 border border-info">
        <div className="form-row bg-info p-2 m-0">
          <div className="col font-bold text-white">Recurring Payroll (optional)</div>
        </div>

        <div className='p-2'>
          <div className="form-row m-0">
            <div className="col-xs-12 col-md-4">
              Is the Payroll Recurring?
            </div>
            <div className="col-xs-12 col-md-8">
              <div>
                <input type='radio' name='is_recurring' value="Yes" checked={isRecurring} onChange={() => setIsRecurring(true)} />Yes
                <input type='radio' name='is_recurring' value="No" checked={!isRecurring} onChange={() => setIsRecurring(false)} />No
              </div>
              <p>If you select Yes, then the Payroll will be automatically added by system every week or month.</p>
            </div>
          </div>

          <div className="form-row py-2 m-0">
            <div className="col-xs-12 col-md-4">
              Add Recurring Payroll
            </div>
            <div className="col-xs-12 col-md-8">
              <select disabled={!isRecurring} name='recurring_info' onChange={handleChange}>
                {new Array(31).fill(0).map((data, index) => (
                  <option>Every {`${index + 1}${index + 1 === 1 ? 'st' : index + 1 >= 2 && index + 1 <= 3 ? 'rd' : 'th'}`} of every month</option>
                ))}
              </select>
            </div>
          </div>
        </div>

      </div>
      {/* end recurring payment */}

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3'>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit} >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default CreatePayroll