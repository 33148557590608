import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import useRequisition from "../../../hooks/useRequisition";
import { formatState } from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AcceptRequisition from "./AcceptRequisition";
import AddRequisition from "./AddRequisition";
import DeclineRequisition from "./DeclineRequisition";
import ReviewRequisition from "./ReviewRequisition";
import ViewRequisition from "./ViewRequisition";
import { Link } from "react-router-dom";

const ApprovedRequisitions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const { loading, requisitions, refresh } = useRequisition("Approved");

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Requisition No",
      selector: (row) => row.purchase_requisition_no,
      reorder: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 3,
      name: "Type",
      selector: (row) => row.type,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Requisition Date",
      selector: (row) => row.requisition_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 5,
      name: "Status",
      selector: (row) => (
        <span className={`badge ${formatState(row.status).className}`}>
          {formatState(row.status).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      id: 6,
      name: "Comment",
      selector: (row) => (row.comment === null ? "" : row.comment),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) =>
        [3, "Declined"].includes(row.status) ? (
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        ) : row.status === "Approved" ? (
          <div className="row">
            <Link
            className="btn btn-sm btn-info mr-1"
            to={`/procurement/requisitions/view/${row.id}`}
          >
            <i className="fa fa-eye"></i>
          </Link>
           

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ) : (
          <div className="row">
            <Link
            className="btn btn-sm btn-info mr-1"
            to={`/procurement/requisitions/view/${row.id}`}
          >
            <i className="fa fa-eye"></i>
          </Link>
            <button
              className="btn btn-sm btn-success mx-1"
              onClick={(e) => {
                e.stopPropagation();
                acceptRequisition(row.id);
              }}
            >
              <i className="fa fa-check"></i>
            </button>
            <button className="btn btn-sm btn-outline-danger">
              <i
                className="fa fa-exclamation-triangle"
                onClick={(e) => {
                  e.stopPropagation();
                  declineRequisition(row.id);
                }}
              ></i>
            </button>

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const reviewRequisition = (id: number) => {
    setOpenModal(1); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const acceptRequisition = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const declineRequisition = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(4); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const viewRequisition = (id: number) => {
    setOpenModal(5); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "review-requisition";
      modalTitle = "Submit your review";
      ModalToRender = ReviewRequisition;
      break;
    case 2:
      modalId = "accept-requisition";
      modalTitle = "Approve requisition";
      ModalToRender = AcceptRequisition;

      break;
    case 3:
      modalId = "decline-requisition";
      modalTitle = "Decline Requisition note";
      ModalToRender = DeclineRequisition;

      break;
    case 4:
      modalId = "delete-requisition";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    case 5:
      modalId = "view-requisition";
      modalTitle = "";
      ModalToRender = ViewRequisition;
      break;
    default:
      modalId = "add-new-requisition";
      modalTitle = "Add New Requisition";
      ModalToRender = AddRequisition;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`purchase_requisitions/${selectedId}`}
            refresh={refresh}
            onCancel={handleModalClick}
          />
        }
      />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable
                columns={columns}
                data={requisitions ?? []}
                title=""
              />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedRequisitions;
