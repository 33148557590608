import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import useRequisition from "../../../hooks/useRequisition";
import useUsers from "../../../hooks/useUsers";
import { formatBillStatus } from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddPurchaseOrder from "./AddPurchaseOrder";
import EditPurchaseOrder from "./EditPurchaseOrder";
import PurchaseOrderReceipt from "./PurchaseOrderReceipt";
import { Link } from "react-router-dom";


const ListPurchaseOrder = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  // const { refresh } = useDepartments();
  const { getPurchaseOrder, purchaseOrder, loading } = useRequisition();
  const { getUserNamesById } = useUsers();
 

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPurchaseOrder(), []);
  const order = {
    id: "PO123456",
    date: "2024-02-16",
    items: [
      { name: "Item 1", quantity: 2, price: 10 },
      { name: "Item 2", quantity: 1, price: 20 },
      { name: "Item 3", quantity: 3, price: 15 },
    ],
    total: 2 * 10 + 1 * 20 + 3 * 15, // Calculated total
    company: "Your Company",
    companyAddress: "123 Main St, City, Country",
    recipient: {
      name: "Recipient Name",
      address: "456 Elm St, City, Country",
    },
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      width: "50px",
    },
    {
      id: 2,
      name: "Reference No",
      selector: (row) => row.reference_no,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Date",
      selector: (row) => row.delivery_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 4,
      name: "Comment",
      selector: (row) => row.comment,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 5,
      name: "Authorised by",
      selector: (row) => getUserNamesById(7),
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 6,
      name: "Status",
      cell: (row) => (
        <span className={`badge ${formatBillStatus(row.status_id).className}`}>
          {" "}
          <i className={`fa ${formatBillStatus(row.status_id).icon}`}></i>{" "}
          {formatBillStatus(row.status_id).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <Link
            className="btn btn-sm btn-success mr-1"
            to={`/procurement/purchase-order/view/${row.id}`}
          >
            <i className="fa fa-eye"></i>
          </Link>
          <button
            className="btn btn-sm btn-info mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(row.id);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };
  const handleAddClick = () => {
    setOpenModal(1); // this opens the add moda
    setIsOpen(true);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the delete modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "add-purchase-order";
      modalTitle = "Add New Purchase Order";
      ModalToRender = AddPurchaseOrder;
      break;
    case 2:
      modalId = "edit-purchase-order";
      modalTitle = "Edit Purchase Order";
      ModalToRender = EditPurchaseOrder;
      break;
    case 3:
      modalId = "delete-purchase-order";
      modalTitle = "Delete Purchase Order";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-new-purchase-order";
      modalTitle = "Add New Purchase Order";
      ModalToRender = AddPurchaseOrder;
      break;
  }

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`purchase-order/${selectedId}/delete`}
            refresh={getPurchaseOrder}
            onCancel={handleModalClick}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddClick();
                    }}
                  >
                    <i className="fa fa-plus mr-1"></i>Add new{" "}
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={purchaseOrder} title="" />
            {/* end table */}
            
            {/* <PurchaseOrderReceipt order={order} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPurchaseOrder;
