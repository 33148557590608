import React, { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component';
import useRequisition from '../../../hooks/useRequisition';
import { formatState } from '../../../utils/helpers';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const ListQuotations = () => {

  const { loading, requisitions } = useRequisition("Reviewed");
  console.log(requisitions);


  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    }, {
      id: 2,
      name: "Reference No",
      selector: (row) => row.reference_no,
      reorder: true,
      grow: 3,
      wrap: true,

    }, {
      id: 3,
      name: "Type",
      selector: (row) => row.type,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 4,
      name: "Requisition Date",
      selector: (row) => row.requisition_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 5,
      name: "Status",
      selector: (row) => <span className={`badge ${formatState(row.status).className}`}>{formatState(row.status).title}</span>,
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      id: 6,
      name: "Comment",
      selector: (row) => row.comment === null ? '' : row.comment,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
  ];



  return (
    <>
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
            </div>
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <i className="fa fa-search"></i>
                <input type="search" className="form-control" placeholder="Search...." />
              </div>
            </div>
          </div>
          {/* table */}
          {loading ? <EllipsisLoader /> : <MyDataTable columns={columns} data={requisitions??[]} title="" />}
          {/* end table */}
        </div>
      </div>

    </>
  )
}

export default ListQuotations